import React from 'react';
import { Box, Text, Button, Image, Tip } from 'grommet';
import { Formik } from 'formik';

import Divider from 'granite/components/Divider';
import FormField from 'granite/components/FormField';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import { updatePtoPolicy } from 'pto/controllers/pto';

import {
  BALANCE_SETTING_VALIDATION_SCHEMA,
  CARRY_OVER_LIMIT_OPTIONS,
  getBalanceSettingInitialValues,
  WAITING_PERIOD_OPTIONS,
} from 'pages/addOrEditPtoPolicy/helper/utils';
import questionMark from 'pages/addOrEditPtoPolicy/icons/question_mark_PNG99.png';
import { StyledBalanceSettingInput, StyledDays, StyledHours, StyledRadioButtonGroup } from 'pages/addOrEditPtoPolicy/styles';
import { PTO_TABS } from '../../../pto/strings';

const BalanceSettingPage = ({ setActiveStep, eventEmitter, data, submitting, setSubmitting, disabled }) => {
  const previousPage = () => {
    GenericWindowPostMessage(PTO_TABS.ACCURAL_TAB, { policyId: data.id });
    setActiveStep(1);
  };
  const onSubmit = async (values, { setErrors }) => {
    if (disabled) {
      GenericWindowPostMessage(PTO_TABS.EMPLOYEE_TAB, {
        policyId: values?.id,
      });
      setActiveStep(3);
      return;
    }
    setErrors({});
    setSubmitting(true);

    const params = {
      accural_config: {
        waiting_period: values?.waiting_period === 'true' ? true : false,
        waiting_period_days: values.waiting_period_days ? parseInt(values.waiting_period_days) : 0,
        carry_over_limit_value: values.carry_over_limit_value ? parseInt(values.carry_over_limit_value) : 0,
        carry_over_limit: values.carry_over_limit === "Yes there's a limit" ? true : false,
      },
      max_balance_limit: values.max_balance_limit,
    };

    updatePtoPolicy(eventEmitter, params, values.id, 2);
  };
  return (
    <Formik
      initialValues={getBalanceSettingInitialValues(data)}
      enableReinitialize
      validationSchema={BALANCE_SETTING_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit, isSubmitting, touched, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box pad={{ top: 'medium', left: 'medium' }}>
            <Text size="14px" color="black">
              Waiting Period
            </Text>
          </Box>
          <Box width="large" pad={{ top: 'medium', left: 'medium', bottom: 'medium' }}>
            <Box width="large" direction="column" pad={{ bottom: 'medium' }}>
              <Text color="rgb(99,108,114);">
                Do your employees go through a waiting period before they may use time off ?{' '}
              </Text>
              <StyledRadioButtonGroup
                name="waiting_period"
                pad={{ top: 'small' }}
                options={WAITING_PERIOD_OPTIONS}
                onChange={handleChange}
                value={values.waiting_period}
                disabled={disabled}
              />
            </Box>
            <Box>
              <FormField
                name="waiting_period_days"
                label={
                  <Text name="waiting_period_days" error={errors.waiting_period_days} size="14px" color="black">
                    Waiting Period Length
                  </Text>
                }
              >
                <Box direction="row" width="fit-content">
                  <StyledBalanceSettingInput
                    size="xsmall"
                    name="waiting_period_days"
                    onChange={handleChange}
                    error={errors.waiting_period_days}
                    value={values.waiting_period_days}
                    disabled={disabled ? true : values.waiting_period === 'true' ? false : true}
                  />
                  <StyledDays
                    background="#eee"
                    width="xsmall"
                    align="center"
                    pad={{ top: '2px' }}
                    waitingPeriod={values?.waiting_period}
                    disabled={disabled}
                  >
                    <Text> Days</Text>
                  </StyledDays>
                </Box>
              </FormField>
              {errors.waiting_period_days && (
                <Text className="invalid-feedback" color="#d9534f !important">
                  {errors.waiting_period_days}
                </Text>
              )}
            </Box>
          </Box>
          <Divider background="#DCDCDC" height="2px" />
          <Box>
            {!data.use_or_lose_it && (
              <Box>
                <Box pad={{ top: 'medium', left: 'medium' }}>
                  <Text size="14px" color="black">
                    Limited Carryover Hours
                  </Text>
                </Box>
                <Box width="large" direction="column" pad={{ top: 'medium', left: 'medium', bottom: 'medium' }}>
                  <Text color="rgb(99,108,114);">
                    Is there a limit to the number of hours your employees can carryover ?
                  </Text>
                  <StyledRadioButtonGroup
                    name="carry_over_limit"
                    width="medium"
                    pad={{ top: 'medium' }}
                    onChange={handleChange}
                    value={values.carry_over_limit}
                    options={CARRY_OVER_LIMIT_OPTIONS}
                    disabled={disabled}
                  />
                </Box>

                <Box width="large" pad={{ left: 'medium' }}>
                  <FormField
                    name="carry_over_limit_values"
                    label={
                      <Text size="14px" color="black">
                        Carryover Limit
                      </Text>
                    }
                  >
                    <Box direction="row" width="fit-content">
                      <StyledBalanceSettingInput
                        size="xsmall"
                        name="carry_over_limit_value"
                        id="carry_over_limit_value"
                        onChange={handleChange}
                        value={values.carry_over_limit_value}
                        disabled={disabled ? true : values.carry_over_limit === "Yes there's a limit" ? false : true}
                      />
                      <StyledHours
                        width="fit-content"
                        align="center"
                        pad={{ left: 'medium', right: 'medium', top: 'xxsmall' }}
                        carryOverLimit={values?.carry_over_limit === "Yes there's a limit"}
                      >
                        <Text>Hours</Text>
                      </StyledHours>
                    </Box>
                  </FormField>
                  {errors.carry_over_limit_value && (
                    <Text className="invalid-feedback" color="#d9534f !important">
                      {errors.carry_over_limit_value}
                    </Text>
                  )}
                </Box>
              </Box>
            )}
            <Box width="large" pad={{ left: 'medium', top: 'medium' }}>
              <FormField
                label={
                  <Box direction="row" align="center">
                    <Text size="14px" color="black">
                      Maximum Balance (optional){' '}
                    </Text>
                    <Tip
                      plain
                      content={
                        <Box
                          pad="small"
                          gap="small"
                          width={{ max: 'medium' }}
                          round="small"
                          background="black"
                          responsive={false}
                        >
                          <Text color="white" size="small">
                            The maximum number of hours an employee can accrue before accrual is paused .
                          </Text>
                        </Box>
                      }
                      dropProps={{ align: { left: 'right' } }}
                    >
                      <Image
                        height="12"
                        width="15"
                        src={questionMark}
                        margin={{ left: 'small' }}
                        pad="small"
                        alt="menu"
                        color="#09819a"
                      />
                    </Tip>
                  </Box>
                }
              >
                <Box direction="row" width="fit-content">
                  <StyledBalanceSettingInput
                    size="xsmall"
                    name="max_balance_limit"
                    placeholder="Maximum Balance"
                    onChange={handleChange}
                    value={values.max_balance_limit}
                    disabled={disabled}
                  />
                  <StyledHours
                    width="fit-content"
                    align="center"
                    pad={{ left: 'medium', right: 'medium', top: 'xxsmall' }}
                    disabled={disabled}
                    carryOverLimit={true}
                  >
                    <Text> Hours</Text>
                  </StyledHours>
                </Box>
              </FormField>
              {errors.max_balance_limit && (
                <Text className="invalid-feedback" color="#d9534f !important">
                  {errors.max_balance_limit}
                </Text>
              )}
            </Box>
          </Box>
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              type="submit"
              label={submitting ? 'Submitting...' : 'Next'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default BalanceSettingPage;
